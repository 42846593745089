const React = require('react');

const Title = require('./components/Title');
const Steps = require('./components/Steps');
const Faq = require('./components/Faq');
const MoreInfoMessage = require('./components/MoreInfoMessage');

const HowBuyInfo = () => (
  <div className="how-buy-landing">
    <Title />
    <Steps />
    <Faq />
    <MoreInfoMessage />
  </div>
);

module.exports = HowBuyInfo;

const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const EditableButtons = require('../EditableButtons');
const Editable = require('../Editable').default;
const InactiveWhatsApp = require('./Icons/InactiveWhatsApp');
const {
  WHATSAPP_WIDGET_ID,
  WHATSAPP_WIDGET_NAME,
  WIDGET_EXTRA_WIDTH,
  WIDGET_EXTRA_HEIGHT,
} = require('../../utils/constants/whatsApp');

const types = require('../Editable/types');

class Wrapper extends React.Component {
  constructor(props) {
    super(props);

    const widgetWhatsApp = props.widgets.find(widget => (widget.key === WHATSAPP_WIDGET_NAME));
    const enabled = !!(widgetWhatsApp && widgetWhatsApp.code);

    this.state = {
      enabled,
      phoneValue: widgetWhatsApp && widgetWhatsApp.config.phone,
      messageValue: widgetWhatsApp && widgetWhatsApp.config.message,
      style: {
        height: 0,
        width: 0,
      },
    };

    this.updateSize = this.updateSize.bind(this);
  }

  componentDidMount() {
    if (!window) {
      return;
    }

    this.updateSize();
  }

  updateSize() {
    const bodyWidget = window.document.getElementById(WHATSAPP_WIDGET_ID);
    const height = bodyWidget && (bodyWidget.offsetHeight + WIDGET_EXTRA_HEIGHT);
    const width = bodyWidget && (bodyWidget.offsetWidth + WIDGET_EXTRA_WIDTH);

    this.setState({
      style: {
        height,
        width,
      },
    });
  }

  render() {
    const { componentId } = this.props;
    const { enabled, style, phoneValue, messageValue } = this.state;

    const editableProps = {
      type: types.WHATSAPP,
      payload: {
        componentId,
        phoneValue,
        messageValue,
      },
    };

    const editableClasses = classNames(
      'whatsapp--editable',
      'editable-wrapper',
    );

    return (
      <div className={editableClasses} style={style}>
        <Editable type={editableProps.type} payload={editableProps.payload}>
          { !enabled && <InactiveWhatsApp className="whatsapp__inactive--editable" /> }
          <EditableButtons editButton={editableProps} showDelete={false} withoutLabel />
        </Editable>
      </div>
    );
  }
}

Wrapper.propTypes = {
  componentId: PropTypes.string.isRequired,
  widgets: PropTypes.shape().isRequired,
};

module.exports = Wrapper;

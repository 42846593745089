const React = require('react');
const PropTypes = require('prop-types');

const wordings = require('./wordings');
const Icons = require('./icons');

const Title = (props, context) => {
  const { i18n } = context;
  const locales = wordings(i18n);

  return (
    <div className="how-buy-landing__steps">
      {
        locales.steps.map(({ icon, title, text }) => {
          const Icon = Icons[icon];
          return (
            <>
              <div className="steps__separator" />
              <div className="steps__step">
                <Icon />
                <h2 className="step__title">{title}</h2>
                <p className="step__text">{text}</p>
              </div>
            </>
          );
        })
      }
    </div>
  );
};

Title.contextTypes = {
  i18n: PropTypes.shape({
    title: PropTypes.string,
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string,
        title: PropTypes.string,
        text: PropTypes.string,
      }).isRequired,
    ).isRequired,
  }),
};

module.exports = Title;

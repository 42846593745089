const React = require('react');
const PropTypes = require('prop-types');

const classNames = require('classnames');

const LabelImage = (props) => {
  const { styles, variations, content } = props;
  const { link, label, theme, behavior } = content;
  const imageClass = classNames('label-image', `label-image--${variations}`, `label-image--behavior-${behavior}`);
  const labelClass = classNames(
    'label-image__text',
    `label-image__text--${theme}`,
    { 'header--custom-background': (theme === 'custom') },
    { 'header--custom-text': (theme === 'custom') },
  );
  const imageStyles = { backgroundImage: `url(${styles.background_image})` };
  return (
    <a className={imageClass} href={link.url}>
      <div className="label-image__background" style={imageStyles}>
        <div className={labelClass}><p>{label}</p></div>
      </div>
    </a>
  );
};

LabelImage.propTypes = {
  styles: PropTypes.shape({
    background_image: PropTypes.string,
  }).isRequired,
  variations: PropTypes.string.isRequired,
  content: PropTypes.shape({
    link: PropTypes.shape({
      url: PropTypes.string,
      onlyLink: PropTypes.bool,
    }).isRequired,
    label: PropTypes.string,
    theme: PropTypes.oneOf(['dark', 'light', 'custom']),
    behavior: PropTypes.oneOf(['show', 'hide', 'hover']),
  }).isRequired,
};

module.exports = LabelImage;

const React = require('react');
const PropTypes = require('prop-types');

const ImageWrapper = require('./ImageWrapper');
const SlideImage = require('./SlideImage');

const classes = require('../../utils/customBEMHelper')({ name: 'slide' });

class Slide extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      extraClass: '',
    };

    this.adjustMountedImage = this.adjustMountedImage.bind(this);
  }

  // we need the slide to be rendered with width of 100vw, and then adjust to 100% when slider is fully loaded
  componentDidMount() {
    this.adjustMountedImage();
  }

  adjustMountedImage() {
    this.setState({ extraClass: 'full-width' });
  }

  render() {
    const { heading, title, subtitle } = this.props;
    const useLayer = heading || title || subtitle;
    return (
      <div className={classes('', this.state.extraClass)}>
        <ImageWrapper action={this.props.action}>
          {useLayer && <div className={classes('layer')} />}
          <SlideImage {...this.props} />
        </ImageWrapper>
      </div>
    );
  }
}

Slide.propTypes = {
  action: PropTypes.string,
  heading: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Slide.defaultProps = {
  action: '',
  heading: '',
  title: '',
  subtitle: '',
};

module.exports = Slide;

const React = require('react');
const PropTypes = require('prop-types');

const wordings = require('./wordings');
const FaqItem = require('./components/FaqItem');

const Faq = (props, context) => {
  const { i18n } = context;
  const locales = wordings(i18n);

  return (
    <div className="how-buy-landing__faqs">
      <h2 className="faqs__title">{locales.title}</h2>
      <ul className="faqs__list">
        {locales.faqs.map(item => (
          <FaqItem key={item.title} title={item.title} text={item.text} />
        ))}
      </ul>
    </div>
  );
};

Faq.contextTypes = {
  i18n: PropTypes.shape({
    title: PropTypes.string,
    faqs: PropTypes.array,
  }),
};

module.exports = Faq;

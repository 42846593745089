const PropTypes = require('prop-types');
const React = require('react');

function Action({ label, target, ...props }) {
  return (
    <a href={target} {...props}>
      {label}
    </a>
  );
}

/**
 * Prop types and defaults
 */
Action.propTypes = {
  target: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

module.exports = Action;

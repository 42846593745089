const React = require('react');
const PropTypes = require('prop-types');

const ImageWrapper = ({ action, children }) => (action
  ? <a href={action}>{children}</a>
  : <>{children}</>
);

ImageWrapper.propTypes = {
  action: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

ImageWrapper.defaultProps = {
  action: '',
};

module.exports = ImageWrapper;
